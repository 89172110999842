<template>
  <v-card
      flat
    >
      <v-card-title class="headline">
        <span
          v-if="dataType"
        >
          {{ $vuetify.lang.t(`$vuetify.healthReport.${dataType}`) }}
        </span>
           -
        <span
          class="text--red"
        >
          {{ $vuetify.lang.t('$vuetify.common.detailDatas') }}
        </span>
      </v-card-title>
      <v-card-text
      >
        <v-expansion-panels
        >
          <v-expansion-panel
            v-for="(day, dayIndex) in days"
            :key="`day-${dayIndex}`"
            @click="handleExpansionPanelclick(day)"
          >
            <v-expansion-panel-header>
              {{ day }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-list
                v-else
                class="transparent"
              >
                <template
                  v-if="dataItems.length == 0"
                >
                  <v-divider class="mx-4"></v-divider>
                  <v-list-item
                  >
                     {{ $vuetify.lang.t('$vuetify.common.noData') }}
                  </v-list-item>
                </template>
                <div
                  v-else
                >
                  <div
                    v-for="(item, index) in dataItems"
                    :key="`item-${index}`"
                  >
                    <v-divider class="mx-4"></v-divider>
                    <v-list-item>
                      <v-row
                        v-if="dataType === 'bloodOxygen'"
                      >
                        <v-col
                          cols="6"
                        >
                            {{item.time | moment('HH:mm') }}
                        </v-col>
                        <v-col
                          cols="6"
                        >
                            {{ $vuetify.lang.t('$vuetify.healthReport.bloodOxygenLabel') }}: {{item.ox}}
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="dataType === 'bloodPressure'"
                      >
                        <v-col
                          cols="4"
                        >
                            {{item.time | moment('HH:mm') }}
                        </v-col>
                        <v-col
                          cols="4"
                        >
                            {{ $vuetify.lang.t('$vuetify.healthReport.systolic') }}: {{item.systolic}}
                        </v-col>
                        <v-col
                          cols="4"
                        >
                            {{ $vuetify.lang.t('$vuetify.healthReport.diastolic') }}: {{item.diastolic}}
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="dataType === 'heartRate'"
                      >
                        <v-col
                          cols="6"
                        >
                            {{item.time | moment('HH:mm') }}
                        </v-col>
                        <v-col
                          cols="6"
                        >
                            {{item.heart}} {{ $vuetify.lang.t('$vuetify.healthReport.timesPerMinute') }}
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="dataType === 'sport'"
                      >
                        <v-col
                          cols="6"
                        >
                            {{item.time | moment('HH:mm') }}
                        </v-col>
                        <v-col
                          cols="6"
                        >
                            {{item.sportStep}} {{ $vuetify.lang.t('$vuetify.healthReport.steps') }}
                        </v-col>
                      </v-row>
                    </v-list-item>
                  </div>
                  <v-pagination
                    v-model="queryParams.params.page"
                    :length="queryParams.params.pageCount"
                  />
                </div>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-actions
      >
        <v-spacer></v-spacer>
        <v-btn
          color="info darken-1"
          @click="handleDialogClose"
        >
          {{ $vuetify.lang.t('$vuetify.common.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
</template>


<script>

  import { mapActions } from 'vuex';

  export default {

    props: {
      dateRange: {
        type: Array,
        default: () => []
      },

      dataType: {
        type: String,
        default: () => null
      },

      patientId: {
        type: String,
        default: () => null
      }
    },
    data () {
      return {
        desserts: [],
        days: [],
        dataItems: [],
        loading: false,
        selectedDay: null,
        queryParams: {
          params: {
            page: 1,
            pageCount: 1
          }
        }
      }
    },

    watch: {
      'queryParams.params.page': {
         handler: function() {
            this.loadData()
         }
      }
    },

    created () {
      this.initialize()
    },

    methods: {

      ...mapActions({
        queryAllBloodOxygenByPatientIdAndTargetDateWithPage: 'healthData/queryAllBloodOxygenByPatientIdAndTargetDateWithPage',
        queryAllBloodPressureByPatientIdAndTargetDateWithPage: 'healthData/queryAllBloodPressureByPatientIdAndTargetDateWithPage',
        queryAllHeartRateByPatientIdAndTargetDateWithPage: 'healthData/queryAllHeartRateByPatientIdAndTargetDateWithPage',
        queryAllSportByPatientIdAndTargetDateWithPage: 'healthData/queryAllSportByPatientIdAndTargetDateWithPage'
      }),


      initialize() {
        let dateFrom = this.$moment(this.dateRange[0])
        let dateTo = this.$moment(this.dateRange[1])
        let dateDiff = dateTo.diff(dateFrom, 'days') + 1
        this.days = Array(dateDiff).fill('').map( (_, i) => {
          let currDate = dateFrom.add(i === 0 ? 0 : 1, 'days')
          return currDate.format('YYYY-MM-DD')
        }).reverse()
      },

      handleDialogClose () {
        this.$emit('close', true)
      },

      handleExpansionPanelclick (day) {
        this.selectedDay = day
        this.queryParams.params.page = 1
        this.queryParams.params.pageCount = 1
        this.loadData()
      },

      loadData () {
        this.loading = true
        this.dataItems = []
        let params =  {}
        params.page = this.queryParams.params.page - 1
        params.sort = ['time,desc']
        params.patientId = this.patientId
        params.targetDate = this.selectedDay
        if (this.dataType === 'bloodOxygen') {
          this.queryAllBloodOxygenByPatientIdAndTargetDateWithPage(params).then( (data) => {
            this.queryParams.params.pageCount = data.totalPages
            this.dataItems = data.content
            this.dataItems.sort( (a, b) => {
              if (a.time < b.time ) {
                  return 1;
                }
                if (a.time > b.time ) {
                  return -1;
                }
                return 0;
            } )
            this.loading = false
          })
        } else if (this.dataType === 'bloodPressure') {
          this.queryAllBloodPressureByPatientIdAndTargetDateWithPage(params).then( (data) => {
            this.queryParams.params.pageCount = data.totalPages
            this.dataItems = data.content
            this.dataItems.sort( (a, b) => {
              if (a.time < b.time ) {
                  return 1;
                }
                if (a.time > b.time ) {
                  return -1;
                }
                return 0;
            } )
            this.loading = false
          })
        } else if (this.dataType === 'heartRate') {
          this.queryAllHeartRateByPatientIdAndTargetDateWithPage(params).then( (data) => {
            this.queryParams.params.pageCount = data.totalPages
            this.dataItems = data.content
            this.dataItems.sort( (a, b) => {
              if (a.time < b.time ) {
                  return 1;
                }
                if (a.time > b.time ) {
                  return -1;
                }
                return 0;
            } )
            this.loading = false
          })
        } else if (this.dataType === 'sport') {
          this.queryAllSportByPatientIdAndTargetDateWithPage(params).then( (data) => {
            this.queryParams.params.pageCount = data.totalPages
            this.dataItems = data.content
            this.dataItems.sort( (a, b) => {
              if (a.time < b.time ) {
                  return 1;
                }
                if (a.time > b.time ) {
                  return -1;
                }
                return 0;
            } )
            this.loading = false
          })
        }

      }
    }
  }
</script>

<style>
.echarts {
  width: 100%;
  height: 100%;
}
</style>
